var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.tablasSicossSelect,"label":"Tabla SICOSS","item-text":"value","item-value":"id","outlined":"","rules":_vm.rules.required,"dense":""},model:{value:(_vm.tablaSelected),callback:function ($$v) {_vm.tablaSelected=$$v},expression:"tablaSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Código DDJJ","dense":"","outlined":"","rules":_vm.rules.required.concat([
                _vm.rules.maxLength(_vm.ddjjCodigo, 10),
                _vm.rules.requiredTrim(_vm.ddjjCodigo)
              ])},model:{value:(_vm.ddjjCodigo),callback:function ($$v) {_vm.ddjjCodigo=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"ddjjCodigo"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"dense":"","outlined":"","label":"Período desde","hint":"Formato AAAAMM","persistent-hint":"","type":"text","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","rules":_vm.rules.required.concat(
                  [_vm.rules.validDateRange(_vm.periodoDesde, _vm.periodoHasta)],
                  _vm.rules.periodoYyyyMm
                )},model:{value:(_vm.periodoDesde),callback:function ($$v) {_vm.periodoDesde=$$v},expression:"periodoDesde"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('######'),expression:"'######'"}],attrs:{"dense":"","outlined":"","label":"Período hasta","type":"text","hint":"Formato AAAAMM","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","persistent-hint":"","rules":_vm.rules.required.concat(
                  [_vm.rules.validDateRange(_vm.periodoDesde, _vm.periodoHasta)],
                  _vm.rules.periodoYyyyMm
                )},model:{value:(_vm.periodoHasta),callback:function ($$v) {_vm.periodoHasta=$$v},expression:"periodoHasta"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-switch',{staticClass:"py-0",attrs:{"label":"Aporte","dense":"","outlined":""},model:{value:(_vm.aporte),callback:function ($$v) {_vm.aporte=$$v},expression:"aporte"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-switch',{staticClass:"py-0",attrs:{"label":"Contribución","dense":"","outlined":""},model:{value:(_vm.contribucion),callback:function ($$v) {_vm.contribucion=$$v},expression:"contribucion"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"auto-grow":"","label":"Descripción","rows":"1","dense":"","outlined":"","rules":_vm.rules.required.concat([
                _vm.rules.maxLength(_vm.descripcion, 150),
                _vm.rules.requiredTrim(_vm.descripcion)
              ])},model:{value:(_vm.descripcion),callback:function ($$v) {_vm.descripcion=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"descripcion"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }