<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <v-row>
            <!-- Select Tabla SICOSS -->
            <v-col cols="6" class="py-0">
              <v-autocomplete
                v-model="tablaSelected"
                :items="tablasSicossSelect"
                label="Tabla SICOSS"
                item-text="value"
                item-value="id"
                outlined
                :rules="rules.required"
                dense
              >
              </v-autocomplete>
            </v-col>

            <!-- Codigo -->
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model.trim="ddjjCodigo"
                label="Código DDJJ"
                dense
                outlined
                :rules="rules.required.concat([
                  rules.maxLength(ddjjCodigo, 10),
                  rules.requiredTrim(ddjjCodigo)
                ])"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- Periodo Desde -->
            <v-col cols="6" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="periodoDesde"
                label="Período desde"
                hint="Formato AAAAMM"
                persistent-hint
                type="text"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
            <!-- Periodo Hasta -->
            <v-col cols="6" class="py-0">
              <v-text-field
                dense
                outlined
                v-model="periodoHasta"
                label="Período hasta"
                type="text"
                hint="Formato AAAAMM"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                persistent-hint
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)],
                    rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="py-0">
              <v-switch
                class="py-0"
                v-model="aporte"
                label="Aporte"
                dense
                outlined
              ></v-switch>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-switch
                class="py-0"
                v-model="contribucion"
                label="Contribución"
                dense
                outlined
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="py-0">
              <v-textarea
                v-model.trim="descripcion"
                auto-grow
                label="Descripción"
                rows="1"
                dense
                outlined
                :rules="rules.required.concat([
                  rules.maxLength(descripcion, 150),
                  rules.requiredTrim(descripcion)
                ])"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditTablasSicoss",
  directives: { mask },
  props: {
    editConfigSicoss: { type: Object }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_CONFIG_SICOSS,
    newTitle: enums.titles.NUEVA_CONFIG_SICOSS,
    rules: rules,
    tablaSelected: null,
    tablasSicossSelect: [],
    idTablaSicoss: null,
    ddjjCodigo: "",
    aporte: false,
    contribucion: false,
    descripcion: "",
    periodoDesde: null,
    periodoHasta: null
  }),
  mounted() {
    if (this.editConfigSicoss) {
      this.setConfigSicoss(this.editConfigSicoss);
    } else {
      this.newConfigSicoss();
    }
    this.sicossSelect();
  },
  methods: {
    ...mapActions({
      getTablasSicossSelect: "aportes/getTablasSicossSelect",
      saveTablaSicoss: "aportes/saveTablaSicoss",
      setAlert: "user/setAlert"
    }),
    closeModal() {
      this.$emit("closeAndReload");
    },
    async sicossSelect() {
      const response = await this.getTablasSicossSelect();
      this.tablasSicossSelect = response;
    },
    async setConfigSicoss(obj) {
      this.tablaSelected = obj.tablaSicossId;
      this.sicossConfigId = obj.sicossConfigId;
      this.ddjjCodigo = obj.ddjjCodigo;
      this.periodoDesde = obj.periodoDesde;
      this.periodoHasta = obj.periodoHasta;
      this.aporte = obj.aporte;
      this.contribucion = obj.contribucion;
      this.descripcion = obj.descripcion;
    },
    async newConfigSicoss() {
      this.formEditTitle = this.newTitle;
    },
    async save() {
      const data = {
        sicossConfigId: this.editConfigSicoss?.sicossConfigId,
        tablaSicossId: this.tablaSelected,
        ddjjCodigo: this.ddjjCodigo,
        periodoDesde: this.periodoDesde,
        periodoHasta: this.periodoHasta,
        aporte: this.aporte,
        contribucion: this.contribucion,
        descripcion: this.descripcion
      };

      const res = await this.saveTablaSicoss(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
      }
    }
  }
};
</script>
