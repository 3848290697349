<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container>
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="loadTablasSicoss()"
              >
                <v-row>
                  <!-- Tabla SICOSS -->
                  <v-col cols="6" sm="6" md="4" class="pb-0">
                    <v-autocomplete
                      v-model="tablaSelected"
                      :items="tablasSicossSelect"
                      label="Seleccionar una tabla SICOSS"
                      item-text="value"
                      item-value="id"
                      outlined
                      required
                      :rules="rules.required"
                      dense
                    >
                    </v-autocomplete>
                  </v-col>

                  <!-- Periodo actual -->
                  <v-col cols="4" class="py-0">
                    <v-switch label="Período actual" v-model="periodoActual">
                    </v-switch>
                  </v-col>
                </v-row>

                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>

        <v-data-table
          :headers="headers"
          :items="itemsRegistrosTablasSicoss"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
          sort-by="value"
          :sort-desc="false"
        >
          <template v-slot:[`item.aporte`]="{ item }">
            <v-icon v-if="item.aporte" small color="primary">
              {{ checkIcon }}
            </v-icon>
          </template>

          <template v-slot:[`item.contribucion`]="{ item }">
            <v-icon v-if="item.contribucion" small color="primary">
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar configuración</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteConfigSicoss(item.sicossConfigId)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar configuración</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="35%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditTablasSicoss
        :editConfigSicoss="editConfigSicoss"
        @closeAndReload="closeAndReload"
      ></EditTablasSicoss>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'20%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditTablasSicoss from "@/components/modules/cuotas/aportes/EditTablasSicoss.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    EditTablasSicoss,
    GoBackBtn,
    FiltersSelected,
    Ayuda
  },
  name: "TablasSicoss",
  directives: { mask },
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    checkIcon: enums.icons.CHECK_OUTLINE,
    isFormValid: false,
    showFilters: true,
    tablaSelected: null,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.TABLA_SICOSS,
    search: "",
    filtersApplied: [],
    tablasSicossSelect: [],
    isLoading: false,
    showDeleteModal: false,
    idToDelete: null,
    periodoActual: false,
    itemsRegistrosTablasSicoss: [],
    editConfigSicoss: {},
    routeToGo: "ConfiguracionDDJJAportes",
    title: enums.titles.TABLAS_SICOSS,
    titleDelete: "¿Eliminar registro?",
    rules: rules,
    headers: [
      {
        text: "Código DDJJ",
        value: "ddjjCodigo"
      },
      {
        text: "Período desde",
        value: "periodoDesde",
        align: "star",
        sortable: false
      },
      {
        text: "Período hasta",
        value: "periodoHasta",
        sortable: false
      },
      {
        text: "Aporte",
        value: "aporte",
        align: "center",
        sortable: false
      },
      {
        text: "Contribución",
        value: "contribucion",
        align: "center",
        sortable: false
      },
      {
        text: "Descripción",
        value: "descripcion",
        sortable: false
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.sicossSelect();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getTablasSicossSelect: "aportes/getTablasSicossSelect",
      getTablasSicossByFilters: "aportes/getTablasSicossByFilters",
      deleteTablaSicoss: "aportes/deleteTablaSicoss",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVA_CONFIGURACION_SICOSS:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_CONFIGURACION_SICOSS:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_CONFIGURACION_SICOSS:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async sicossSelect() {
      const response = await this.getTablasSicossSelect();
      this.tablasSicossSelect = response;
    },

    resetForm() {
      this.$refs["filters-form"].reset();
    },
    // metodos de filtros
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.tablaSelected) {
        const tablaNombre = this.tablasSicossSelect.find(
          x => x.id === this.tablaSelected
        );
        this.filtersApplied.splice(0, 1, {
          key: "tablaSelected",
          label: "Tabla SICOSS",
          model: tablaNombre.value
        });
      }
      if (this.periodoActual) {
        const periodoActual = "SÍ";
        this.filtersApplied.splice(1, 1, {
          key: "periodoActual",
          label: "Período actual",
          model: periodoActual
        });
      } else {
        const periodoActual = "NO";
        this.filtersApplied.splice(1, 1, {
          key: "periodoActual",
          label: "Período actual",
          model: periodoActual
        });
      }
    },
    async loadTablasSicoss() {
      this.isLoading = true;
      const datosFiltrados = {
        tablaSicossId: this.tablaSelected,
        periodoActual: this.periodoActual
      };
      this.customizeFiltersApplied();
      if (datosFiltrados.tablaSicossId == null) return;
      const data = await this.getTablasSicossByFilters(datosFiltrados);
      this.itemsRegistrosTablasSicoss = data;
      this.showFilters = false;
      this.isLoading = false;
    },
    deleteConfigSicoss(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteTablaSicoss({
        sicossConfigId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadTablasSicoss();
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.editConfigSicoss = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadTablasSicoss();
      this.isLoading = false;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
